
<template>
  <div>
    <Divider dashed>
      <span class="divider-text">周期分项统计</span>
    </Divider>
    <Table
           stripe :data="agentBillList"
          :columns="tableColumns"
          show-summary
          :summary-method="handleSummary"
        ></Table>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
export default {
  data () {
    return {
      feeTotal: 0,

      tableColumns: [
        { title: '费用类型', align: 'left', key: 'feeTypeName' },
        {
          title: '费用金额',
          align: 'right',
          key: 'executedAmount',
          render: (h, param) => {
            return h('span', toMoney(param.row.executedAmount))
          }
        }
      ]
    }
  },
  created () {
    if (this.agentBillList && this.agentBillList.length > 0) {
      this.feeTotal = this.agentBillList
        .map((x) => x.executedAmount)
        .reduce((prev, cur) => {
          return prev + cur
        })
    }
  },
  methods: {
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (this.agentBillList && this.agentBillList.length > 0) {
          var total = this.agentBillList
            .map((x) => x.executedAmount)
            .reduce((prev, cur) => {
              const value = Number(cur)
              if (!isNaN(value)) {
                return prev + cur
              } else {
                return prev
              }
            })
          sums[key] = {
            key,
            value: toMoney(total)
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })
      return sums
    },
    htmlToMoney (obj) {
      return toMoney(obj)
    }
  },
  computed: {
    agentBillList () {
      return this.$store.state.supplierReconciliation.agentBillList
    }
  }
}
</script>
